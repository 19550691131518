@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400&display=swap");

body {
    margin: 0;
    font-family: "Raleway", -apple-system,
        BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --text-color: white;
    --heading-fonts: "Lora";
    background-color: #030031;
    color: white;
}

h1 {
    font-size: 3rem !important;
    font-family: var(--heading-fonts);
    font-weight: 400 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas,
        "Courier New", monospace;
}

.collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
